// base url
export var nonPciApiHost = 'https://api.victoriassecret.com';

export const employeeIDPath = 'vsemployeeidlookup/v1/employeeid';

var windowHostname = location.host;
if (windowHostname.includes('test') || windowHostname.includes('perf') || windowHostname.includes('localhost')) {
  nonPciApiHost = 'https://apitest.victoriassecret.com';
  // config for local
  // nonPciApiHost = 'http://localhost:8081'
}
